<template>
  <div :class="{ 'cancelled-event': isCancelled }">
    {{ eventName }}
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EventNameComponent',
  props: {
    eventName: {
      type: String,
      default: '',
    },
    isCancelled: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.cancelled-event {
  text-decoration: line-through;
  opacity: 0.7;
}
</style>
