<template>
  <div>
    <span v-if="includeIconAndLabel"
    ><i class="far fa-stopwatch fa-fw me-2"></i
      >{{ $t('EVENTS.LABEL.DURATION') }}:</span
    >
    <span v-if="durationHours > 0">
      {{ durationHours }}{{ $t('GENERAL.LABEL.HOURS_ABBREVIATION') }}
    </span>
    <span v-if="durationMinutes > 0">
      {{ durationMinutes }}{{ $t('GENERAL.LABEL.MINUTES_ABBREVIATION') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'EventDurationComponent',
  props: {
    durationHours: {
      type: Number,
      default: 0,
    },
    durationMinutes: {
      type: Number,
      default: 0,
    },
    includeIconAndLabel: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
