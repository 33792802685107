<template>
  <span>
    <i :class="`${eventTypeClasses} ${iconSizeClass} me-1`"></i>
    <span class="d-none d-lg-inline-flex">{{ eventTypeLabel }}</span>
  </span>
</template>

<script>
import * as EventUtils from '@/utils/events';

export default {
  name: 'EventTypeComponent',
  props: {
    eventType: {
      type: String,
      default: '',
    },
    iconSizeClass: {
      type: String,
      default: 'h4',
    },
  },
  computed: {
    eventTypeLabel() {
      const key = EventUtils.getEventTypeLabelKey(this.eventType);
      return this.$t(key);
    },
    eventTypeClasses() {
      return EventUtils.getEventTypeIconClasses(this.eventType);
    },
  },
};
</script>

<style scoped></style>
