<template>
  <div
    class="d-flex flex-row align-items-baseline justify-content-start flex-wrap"
  >
    <span
      v-if="event.is_registered"
      class="is-registered d-flex align-items-center justify-content-between me-2"
    >
      <i class="fal fa-check c-white"></i>
      <span class="smaller d-none d-md-inline-block ms-md-2">{{
        $t('EVENTS.LABEL.REGISTERED')
      }}</span>
    </span>
    <span
      v-else-if="event.is_waitlist_registered"
      class="is-waitlist-registered d-flex align-items-center justify-content-between me-2"
    >
      <i class="fal fa-check c-white"></i>
      <span class="smaller d-none d-md-inline-block ms-md-2">{{
        $t('EVENTS.LABEL.ON_WAITLIST')
      }}</span>
    </span>
    <span
      v-else-if="isEventFull"
      class="is-waitlist d-flex align-items-center justify-content-between me-2"
    >
      <span class="smaller d-md-inline-block">{{
        $t('EVENTS.LABEL.WAITLIST_TAG')
      }}</span>
    </span>
    <span
      class="registration me-2"
      v-if="
        !hasRecordings &&
        event.registration_required &&
        (!event.hide_registration_info || displayRegistrations)
      "
      v-tooltip="{
        content: $t('EVENTS.LABEL.REQUIRES_REGISTRATION'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <i
        class="fal fa-clipboard-user c-dark-gray me-2"
        :class="{ smaller: small }"
      ></i>
      <span class="smaller">
        {{ event.number_of_registrations }}
        <span v-if="event.registration_limit"
          >/ {{ event.registration_limit }}
        </span>
      </span>
    </span>
    <span
      v-if="event.is_punchable_event"
      v-tooltip="{
        content: $t('EVENTS.LABEL.CLASSCARD_ALLOWED'),
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <i
        class="fal fa-ticket-alt c-coral p-1 me-1 rotate-35"
        :class="{ smaller: small }"
      ></i>
    </span>
    <EventTypeComponent
      v-if="!hasRecordings"
      class="c-dark-gray flex-nowrap"
      :class="{ smaller: small }"
      :eventType="event.event_type"
      iconSizeClass=""
    />
    <div class="d-flex flex-wrap" v-if="!hasRecordings">
      <span class="ms-1 d-none d-md-flex" :class="{ smaller: small }">-</span>
      <EventDurationComponent
        class="ms-1"
        :class="{ smaller: small }"
        :durationHours="event.duration_hours"
        :durationMinutes="event.duration_minutes"
        :includeIconAndLabel="false"
      />
    </div>
    <div
      class="d-flex justify-content-start align-items-baseline"
      v-if="hasRecordings"
    >
      <i class="fad fa-play-circle c-brand-color me-2"></i>
      <span class="small c-brand-color" v-if="isExpired">
        {{ $t('EVENTS.LABEL.REPLAY_AVAILABLE') }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import EventTypeComponent from '@/components/events/EventTypeComponent';
import EventDurationComponent from '@/components/events/EventDurationComponent';

export default defineComponent({
  name: 'StudioEventTypeComponent',
  components: {
    EventTypeComponent,
    EventDurationComponent,
  },
  props: {
    event: {
      type: Object,
    },
    small: {
      type: Boolean,
      default: true,
    },
    displayRegistrations: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasRecordings() {
      return this.event.has_available_recordings;
    },
    isExpired() {
      return this.event?.status === 'expired';
    },
    isEventFull() {
      if (!this.event.registration_required) return false;
      if (this.event.registration_limit === 0) return false;
      if (this.event.registration_limit > this.event.number_of_registrations)
        return false;

      return true;
    },
  },
});
</script>

<style lang="scss" scoped>
  @import '@/styles/components-variables.scss';

.registration {
  background-color: $lightest-gray;
  padding: 0 6px;
  border-radius: 6px;
}
.is-registered {
  background-color: $light-green;
  padding: 2px 6px;
  border-radius: 6px;
  color: $white;
}
.is-waitlist-registered {
  background-color: $mustard;
  padding: 2px 6px;
  border-radius: 6px;
  color: $white;
}
.is-waitlist {
  background-color: $light-gray;
  padding: 2px 6px;
  border-radius: 6px;
}
</style>
